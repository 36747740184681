import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  AsyncTypeahead,
  Typeahead,
  TypeaheadMenu,
  Input,
  Hint,
} from 'react-bootstrap-typeahead';

let formId = 0;

export const TypeAheadWrapper = ({
  url,
  items,
  name,
  onChange,
  selected,
  isFetching,
  setIsFetching,
}) => {
  const callSetIsFetching = val => {
    if (setIsFetching) {
      setIsFetching(val);
    }
  };
  const id = `typeahead-form-${useMemo(() => formId++, [])}`;
  const [state, setState] = useState({
    organisations: [],
  });
  const [inputVal, setInputVal] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const onSearch = async query => {
    if (query.length < 3) {
      return;
    }
    callSetIsFetching(true);
    try {
      let response = await fetch(url + `?q=${query}`);

      while (response.status === 202) {
        await new Promise(r => setTimeout(r, 500));
        response = await fetch(url + `?q=${query}`);
      }

      const organisations = await response.json();

      if (!Array.isArray(organisations)) {
        console.error(organisations);
        throw new Error('Cannot process organisation response');
      }

      setState({
        organisations,
      });
      callSetIsFetching(false);
    } catch (err) {
      callSetIsFetching(false);
      throw err;
    }
  };
  const commonProps = {
    selected,
    onChange,
    onInputChange: inputText => {
      setInputVal(inputText);
      if (!items) {
        onSearch(inputText);
      }
    },
    className: classNames(
      `typeahead__component`,
      isOpen && 'typeahead__component--open'
    ),
    id,
    onMenuToggle: setIsOpen,
    inputProps: { name },
    renderMenu(results, menuProps, parentProps) {
      if (isFetching) {
        return (
          <div className="typeahead__menu-overlay">
            <div className="typeahead__menu-wrapper">
              <div className="rbt-menu dropdown-menu show typeahead__menu">
                <div className="dropdown-item disabled">
                  Searching for organisations&hellip;
                </div>

                {[50, 30, 70].map((value, index) => (
                  <div key={index} className="dropdown-item disabled">
                    <div
                      className="dropdown-item__loader"
                      style={{ width: `${value}%` }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className="typeahead__menu-overlay">
          <div className="typeahead__menu-wrapper">
            <TypeaheadMenu
              {...menuProps}
              style={undefined}
              className="typeahead__menu"
              labelKey={parentProps.labelKey}
              options={results}
              text={parentProps.text}
            />
          </div>
          <p className="typeahead__menu-hint">
            <span className="stack stack--center stack--space-1">
              <span>
                Remember to try alternative spellings and abbreviations
              </span>
              <Link
                to={`/signup/add-organisation?prefillOrganisation=${inputVal}`}
                className="typeahead__menu-link"
              >
                I can&apos;t find my organisation
              </Link>
            </span>
          </p>
        </div>
      );
    },
    // eslint-disable-next-line react/prop-types
    renderInput({ inputRef, referenceElementRef, ...inputProps }) {
      return (
        <Hint className="typeahead__placeholder-hint">
          <Input
            {...inputProps}
            ref={node => {
              inputRef(node);
              referenceElementRef(node);
            }}
          />
        </Hint>
      );
    },
  };

  if (url) {
    return (
      <AsyncTypeahead
        {...commonProps}
        isLoading={isFetching || false}
        useCache={false}
        onSearch={onSearch}
        options={state.organisations}
      />
    );
  } else if (items) {
    return <Typeahead {...commonProps} options={items} />;
  }

  return null;
};

TypeAheadWrapper.propTypes = {
  isFetching: PropTypes.bool,
  setIsFetching: PropTypes.func,
  url: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  name: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.string,
};

export const TypeAhead = ({ labelText, hint, ...rest }) => (
  <label className="typeahead">
    {labelText}
    <TypeAheadWrapper {...rest} />
    {hint && <span className="typeahead__hint">{hint}</span>}
  </label>
);

TypeAhead.propTypes = {
  ...TypeAheadWrapper.propTypes,
  labelText: PropTypes.string.isRequired,
  hint: PropTypes.node,
};

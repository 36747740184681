class BreadcrumbDropdown {
  constructor(node) {
    this.node = node;
    this.breadcrumb = document.querySelector('[data-breadcrumb-container]');
    this.label = this.breadcrumb.querySelector('[data-breadcrumb-label]');
    this.list = this.breadcrumb.querySelector('[data-breadcrumb-list]');
    this.bindEvents();
  }

  mobileToggle() {
    this.breadcrumb.addEventListener('click', () => {
      this.label.classList.toggle('active');
      this.list.classList.toggle('block');

      setTimeout(() => {
        this.list.classList.toggle('active');
      }, 80);
    });
  }

  desktopToggle() {
    this.breadcrumb.addEventListener('mouseenter', () => {
      this.label.classList.add('active');
      this.list.classList.add('block');

      setTimeout(() => {
        this.list.classList.add('active');
      }, 80);

      this.list.setAttribute('aria-expanded', 'true');
      this.list.setAttribute('aria-hidden', 'false');
    });

    this.breadcrumb.addEventListener('mouseleave', () => {
      this.label.classList.remove('active');
      this.list.classList.remove('block');

      setTimeout(() => {
        this.list.classList.remove('active');
      }, 80);

      this.list.setAttribute('aria-expanded', 'false');
      this.list.setAttribute('aria-hidden', 'true');
    });
  }

  bindEvents() {
    if (window.innerWidth > 800) {
      this.desktopToggle();
    } else {
      this.mobileToggle();
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (
    document.body.contains(document.querySelector('[data-breadcrumb-label]'))
  ) {
    new BreadcrumbDropdown();
  }
});

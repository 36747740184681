import stickybits from 'stickybits';
import 'core-js/features/promise';

import { debounce } from '../../lib/debounce';

document.addEventListener('DOMContentLoaded', () => {
  const instance = stickybits('[data-sticky-backtotop]', {
    verticalPosition: 'bottom',
    useFixed: true,
    stickyBitStickyOffset: 60,
    parentClass: 'back-to-top__parent',
    stuckClass: 'back-to-top--stuck',
    stickyClass: 'back-to-top--sticky',
    stickyChangeClass: 'back-to-top--change',
  });

  var observer = new MutationObserver(() => {
    instance.update();
  });

  observer.observe(document, {
    subtree: true,
    childList: true,
  });

  window.addEventListener(
    'resize',
    debounce(() => {
      instance.update();
    })
  );
});

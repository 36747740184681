import React from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

const classNames = {
  overlay: 'modal__overlay',
  content: 'modal__content',
};

const Modal = React.forwardRef(
  (
    { onEscape, onOverlayClick, children, isOpen, className, ...props },
    ref
  ) => {
    if (!isOpen) {
      return null;
    }

    return (
      <FocusTrap>
        {isOpen && (
          <div
            role="dialog"
            aria-modal="true"
            tabIndex="0"
            onKeyDown={evt => {
              if (evt.key === 'Escape') {
                onEscape();
              }
            }}
            ref={ref}
            className={className}
            {...props}
          >
            <div
              className={[
                classNames.overlay,
                className && `${className}__overlay`,
              ]
                .filter(Boolean)
                .join(' ')}
              onClick={onOverlayClick}
            />
            <div
              className={[
                classNames.content,
                className && `${className}__content`,
              ]
                .filter(Boolean)
                .join(' ')}
              onClick={evt => {
                if (evt.target === evt.currentTarget) {
                  onOverlayClick();
                }
              }}
            >
              {children}
            </div>
          </div>
        )}
      </FocusTrap>
    );
  }
);

Modal.displayName = 'Modal';

Modal.propTypes = {
  onEscape: PropTypes.func,
  onOverlayClick: PropTypes.func,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

export default Modal;

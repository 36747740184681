document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector('.nav-local');
  if (el) {
    const mobileToggle = el.querySelector('.nav-local__mobile-toggle');

    mobileToggle.addEventListener('click', function() {
      const isExpanded = this.getAttribute('aria-expanded') === 'true';
      this.setAttribute('aria-expanded', !isExpanded);
    });

    document.addEventListener('click', function(event) {
      if (!el.contains(event.target)) {
        mobileToggle.setAttribute('aria-expanded', false);
      }
    });
  }
});

import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { createModule } from '../../lib/createModule';

import Modal from './modal';

const SearchInputModal = ({ isOpen, searchUrl, onClose }) => (
  <Modal
    isOpen={isOpen}
    onEscape={onClose}
    onOverlayClick={onClose}
    className="search-input-modal"
    aria-label="search modal"
  >
    <div className="search-input-modal__close-container">
      <button
        onClick={onClose}
        className="search-input-modal__close"
        aria-label="close"
      >
        <svg width="25" height="25" viewBox="0 0 25 25">
          <g
            transform="rotate(-45 15.328 5.672)"
            fill="#51296A"
            fillRule="evenodd"
          >
            <rect x="7" width="3" height="17" rx="1" />
            <path d="M17 8v1a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1z" />
          </g>
        </svg>
      </button>
    </div>

    <form
      role="search"
      className="search-input__form"
      method="get"
      action={searchUrl}
    >
      <div className="search-input__field">
        <label className="search-input__label">
          <span className="visually-hidden">Search site content</span>
          <input
            type="search"
            placeholder="Search..."
            className="search-input__input"
            name="q"
          />
        </label>

        <button
          type="submit"
          aria-label="submit"
          className="search-input__submit"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
            <path
              d="M6.38 19.027l2.203-3.816a.75.75 0 0 0 .074-.57l-.132-.492c1.289.548 2.761.684 4.219.293A6.5 6.5 0 1 0 9.379 1.884a6.5 6.5 0 0 0-4.595 7.961 6.47 6.47 0 0 0 2.363 3.508l-.492.132a.75.75 0 0 0-.456.349L3.996 17.65c-.208.36-.085.819.274 1.023l1.083.625c.36.208.819.085 1.027-.272zm.785-9.773a4 4 0 0 1 7.727-2.071 4 4 0 0 1-7.727 2.071z"
              fill="currentColor"
            />
          </svg>
        </button>
      </div>
    </form>
  </Modal>
);

SearchInputModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  searchUrl: PropTypes.string,
};

let container;
let globalProps = {
  isOpen: false,
};

globalProps.onClose = () => {
  globalProps.isOpen = false;

  render();
};

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <SearchInputModal {...globalProps} />
    </React.StrictMode>,
    container
  );
};

const initSearchModal = () => {
  if (!container) {
    container = document.createElement('div');
    container.className = 'search-input-modal__root';
    document.body.appendChild(container);
  }

  render();
};

export const updateSearchModal = props => {
  globalProps = {
    ...globalProps,
    ...props,
  };

  render();
};

createModule('search-input-module', globalNamespace => {
  globalNamespace.updateSearchModal = updateSearchModal;
});

initSearchModal();

document.addEventListener('DOMContentLoaded', () => {
  const toggle = document.querySelector('.search-input__toggle');

  toggle?.addEventListener('click', () => {
    window.ContentComponents.updateSearchModal({
      isOpen: !globalProps.isOpen,
    });
  });
});

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.nav-tree').forEach(navtreeRoot => {
    const toggles = navtreeRoot.querySelectorAll('.nav-tree__toggle');
    const mobileToggle = navtreeRoot.querySelector(':scope > button');

    if (mobileToggle) {
      mobileToggle.addEventListener('click', function() {
        const isExpanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', !isExpanded);
      });
    }

    toggles.forEach(toggle => {
      toggle.addEventListener('click', function() {
        const parentclasslist = this.closest('.nav-tree__item').classList;

        if (!parentclasslist.contains('nav-tree__item--active')) {
          const isExpanded = this.getAttribute('aria-expanded') === 'true';

          this.setAttribute('aria-expanded', !isExpanded);

          parentclasslist.toggle('nav-tree__item--open');
          parentclasslist.toggle('nav-tree__item--closed');
        }
      });
    });
  });
});

// Adapted from Inclusive Components - https://inclusive-components.design/tabbed-interfaces/

const findIndex = (items, finder) => {
  for (let i = 0; i < items.length; i++) {
    if (finder(items[i])) {
      return i;
    }
  }

  return -1;
};

const updateActiveTab = (index, activeTabId) => {
  if (history.pushState) {
    const queryParam = `tabbed_active${index}`;
    const url = new URL(location.href);

    url.searchParams.set(queryParam, activeTabId);

    history.pushState({}, document.title, url);
    updatePageLinks(queryParam, activeTabId);
  }
};

const getActiveTab = index => {
  const queryParam = `tabbed_active${index}`;
  const url = new URL(location.href);

  return url.searchParams.get(queryParam);
};

// TODO: Abstract this
// find all links to this page using a marker in the DOM and update the href
const updatePageLinks = (queryParam, queryValue) => {
  document.querySelectorAll('a.cc-js-hook-update-href').forEach(el => {
    const url = new URL(el.href);

    url.searchParams.set(queryParam, queryValue);

    el.href = url.toString();
  });
};

document.addEventListener('DOMContentLoaded', () => {
  // Get relevant elements and collections
  const tabbedEls = document.querySelectorAll('.tabbed');

  tabbedEls.forEach((tabbed, tabbedIndex) => {
    const tablist = tabbed.querySelector('.tabbed__tablist');
    const panels = tabbed.querySelectorAll('.tabbed__tabpanel');
    const tabs = tablist.querySelectorAll('a.tabbed__tablist__anchor');

    // The tab switching function
    const switchTab = (oldTab, newTab) => {
      newTab.focus();
      // Make the active tab focusable by the user (Tab key)
      newTab.removeAttribute('tabindex');
      // Set the selected state
      newTab.setAttribute('aria-selected', 'true');
      oldTab.removeAttribute('aria-selected');
      oldTab.setAttribute('tabindex', '-1');
      // Get the indices of the new and old tabs to find the correct
      // tab panels to show and hide
      const index = Array.prototype.indexOf.call(tabs, newTab);
      const oldIndex = Array.prototype.indexOf.call(tabs, oldTab);

      panels[oldIndex].hidden = true;
      panels[index].hidden = false;

      if (newTab.hash) {
        const id = newTab.hash.slice(1);

        updateActiveTab(tabbedIndex, id);
      }
    };

    // Add the tablist role
    if (tablist) {
      tablist.setAttribute('role', 'tablist');

      if (tabbed.classList.contains('tabbed--tabs-above')) {
        tabbed.style.marginTop = `-${tablist.offsetHeight}px`;
      }
    }

    // Add semantics are remove user focusability for each tab
    tabs.forEach((tab, tabIndex) => {
      tab.setAttribute('role', 'tab');
      tab.setAttribute('id', 'tab' + (tabIndex + 1));
      tab.setAttribute('tabindex', '-1');
      tab.parentNode.setAttribute('role', 'presentation');

      // Handle clicking of tabs for mouse users
      tab.addEventListener('click', e => {
        e.preventDefault();

        const tabHref = e.target.getAttribute('href');
        const tabSectionElement = document.getElementById(tabHref.substring(1));

        tabSectionElement.scrollIntoView();
        const currentTab = tablist.querySelector('[aria-selected]');
        if (e.currentTarget !== currentTab) {
          switchTab(currentTab, e.currentTarget);
        }
      });

      // Handle keydown events for keyboard users
      tab.addEventListener('keydown', e => {
        // Get the index of the current tab in the tabs node list
        const index = Array.prototype.indexOf.call(tabs, e.currentTarget);
        // Work out which key the user is pressing and
        const isLeft = e.which === 37;
        const isRight = e.which === 39;
        const isDown = e.which === 40;

        if (isLeft || isRight || isDown) {
          e.preventDefault();
        }

        // If the down key is pressed, move focus to the open panel,
        if (isDown) {
          panels[tabIndex].focus();
        }

        // otherwise switch to the adjacent tab
        if (isLeft || isRight) {
          const dir = isLeft ? index - 1 : index + 1;

          if (tabs[dir]) {
            switchTab(e.currentTarget, tabs[dir]);
          }
        }
      });
    });

    let startIndex = 0;
    const defaultTabIndexString = tablist.getAttribute(
      'data-default-tab-index'
    );

    if (defaultTabIndexString) {
      try {
        const defaultTabIndex = parseInt(defaultTabIndexString);
        startIndex = defaultTabIndex;
      } catch (e) {
        console.error('Invalid Tab Index');
      }
    } else {
      const startActiveTab = getActiveTab(tabbedIndex);
      if (startActiveTab !== null) {
        const foundIndex = findIndex(panels, el => el.id === startActiveTab);

        if (foundIndex !== -1) {
          startIndex = foundIndex;
        }
      }
    }
    // Activate panel associated with start index
    tabs[startIndex].setAttribute('aria-selected', 'true');
    tabs[startIndex].removeAttribute('tabindex');

    // initialize active tab
    // Add tab panel semantics and hide them all
    panels.forEach((panel, i) => {
      panel.setAttribute('role', 'tabpanel');
      panel.setAttribute('tabindex', '-1');
      panel.setAttribute('aria-labelledby', tabs[i].id);

      if (i === startIndex) {
        panel.hidden = false;
      } else {
        panel.hidden = true;
      }
    });
  });
});

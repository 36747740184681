const ContentComponents = (window.ContentComponents =
  window.ContentComponents || {});

const modules = {};

export const createModule = (name, factory) => {
  if (modules[name] && process.env.NODE_ENV !== 'production') {
    console.warn(`A module with "${name}" was already loaded`);
  }
  modules[name] = true;

  factory(ContentComponents);
};

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.promo-card a').forEach(el => {
    const href = el.getAttribute('href');
    const cardEl = el.closest('.promo-card');

    // to avoid double navigation, prevent hyperlink from changing window.location
    el.addEventListener('click', event => {
      event.preventDefault();
    });

    // allow the entire card region to be interactive
    cardEl.addEventListener('click', () => {
      window.location = href;
    });
  });
});

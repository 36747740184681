import React from 'react';
import ReactDOM from 'react-dom';
import { TypeAhead } from './TypeAhead';

// TODO: abstract this pattern so it's easier to set the react component
// using data attributes as props
document.addEventListener('DOMContentLoaded', () => {
  const elements = document.getElementsByClassName('typeahead__root');

  for (const el of elements) {
    const dataUrl = el.dataset.url;
    const dataItems = el.dataset.items
      ? JSON.parse(el.dataset.items)
      : undefined;
    const label = el.dataset.label;
    const name = el.dataset.name;

    if ((!dataUrl && !dataItems) || (dataUrl && dataItems)) {
      console.warn(
        `Please provide either data-url or data-items as stringified JSON, on el `,
        el
      );
      return;
    }

    ReactDOM.render(
      <React.StrictMode>
        <TypeAhead
          items={dataItems}
          url={dataUrl}
          labelText={label}
          name={name}
          onChange={console.log}
        />
      </React.StrictMode>,
      el
    );
  }
});

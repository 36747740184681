import Glide from '@glidejs/glide';

class Carousel {
  constructor() {
    this.node = document.querySelector('[data-carousel]');

    this.slideTotal = document.querySelector(
      '[data-carousel]'
    ).dataset.slidetotal;

    this.carousel = new Glide(document.querySelector('[data-carousel]'), {
      type: 'slider',
      perView: 3,
      gap: 40,
      animationTimingFunc: 'ease',
      animationDuration: 450,
      autoplay: false,
      hoverpause: true,
      breakpoints: {
        1024: {
          perView: 2,
        },
        800: {
          perView: 1,
        },
      },
    });

    this.carousel.mount();

    this.bindEvents();
    this.setLiveRegion();
  }

  bindEvents() {
    this.carousel.on('move.after', () => {
      this.updateAriaRoles();
      this.updateLiveRegion();
    });
  }

  // sets aria-hidden on inactive slides
  updateAriaRoles() {
    // eslint-disable-next-line no-restricted-syntax
    for (const slide of this.node.querySelectorAll(
      '.glide__slide:not(.glide__slide--active)'
    )) {
      slide.setAttribute('aria-hidden', 'true');
      slide.setAttribute('tab-index', 0);
    }
    const activeSlide = this.node.querySelector('.glide__slide--active');
    activeSlide.removeAttribute('aria-hidden');
  }

  // Sets a live region. This will announce which slide is showing to screen readers when previous / next buttons clicked
  setLiveRegion() {
    const controls = this.node.querySelector('[data-glide-el="controls"]');
    const liveregion = document.createElement('div');

    liveregion.setAttribute('aria-live', 'polite');
    liveregion.setAttribute('aria-atomic', 'true');
    liveregion.setAttribute('class', 'carousel__liveregion');
    liveregion.setAttribute('data-liveregion', true);

    controls.appendChild(liveregion);
  }

  // Update the live region that announces the next slide.
  updateLiveRegion() {
    const slideLabel = `Item ${this.carousel.index} of ${this.slideTotal}`;

    this.node.querySelector('[data-liveregion]').textContent = slideLabel;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (document.body.contains(document.querySelector('[data-carousel]'))) {
    new Carousel();
  }
});

import { createPopper } from '@popperjs/core';

const showEvents = ['mouseenter', 'focus'];
const hideEvents = ['mouseleave', 'blur'];

// make use of popperjs library to avoid author details colliding with
// the browser window
document.addEventListener('DOMContentLoaded', () => {
  const authorsEls = document.querySelectorAll('.author__trigger');

  [].forEach.call(authorsEls, function(authorEl) {
    const tooltip = authorEl.nextElementSibling;

    if (!tooltip?.classList.contains('author__details')) {
      return;
    }

    const popperInstance = createPopper(authorEl, tooltip, {
      placement: 'auto',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [10, 20],
          },
        },
        {
          name: 'flip',
          options: {
            allowedAutoPlacements: ['bottom', 'right', 'left'],
          },
        },
      ],
    });

    const show = () => {
      tooltip.setAttribute('data-show', '');

      // we need to tell Popper to update the tooltip position after
      // we show the tooltip
      popperInstance.update();
    };

    const hide = () => {
      tooltip.removeAttribute('data-show');
    };

    showEvents.forEach(event => {
      authorEl.addEventListener(event, show);
    });

    hideEvents.forEach(event => {
      authorEl.addEventListener(event, hide);
    });
  });
});

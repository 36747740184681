document.addEventListener('DOMContentLoaded', () => {
  const dropdowns = document.querySelectorAll(
    '.dropdown[data-navigate="true"]'
  );

  [].forEach.call(dropdowns, dropdown => {
    dropdown.addEventListener('change', event => {
      document.location.href = event.target.value;
    });
  });
});

import stickybits from 'stickybits';

document.addEventListener('DOMContentLoaded', () => {
  stickybits('[data-sticky-stepsnavsticky]', {
    verticalPosition: 'bottom',
    useFixed: true,
    stickyBitStickyOffset: 0,
    parentClass: 'steps-nav-sticky__parent',
    stuckClass: 'steps-nav-sticky--stuck',
    stickyClass: 'steps-nav-sticky--sticky',
    stickyChangeClass: 'steps-nav-sticky--change',
  });
});
